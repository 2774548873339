import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { LOG } from '../URL'

const Log = {}
const URL = LOG

Log.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', -1)
    FilterManager(data, page)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Log