import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { DASHBOARD } from '../URL'

const Dashobard = {}
const URL = DASHBOARD

Dashobard.notification = async function (p, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 9999)

    fetch({
        url: URL.NOTIFICATION,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.takip = async function (_, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.TAKIP,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.annoucements = async function (orderColumnAndDirection, offset, limit, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', orderColumnAndDirection)
    data.append('offset', offset)
    data.append('limit', limit)

    fetch({
        url: URL.ANNOUNCEMENT,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.islem_yolcular = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.ISLEM_YOLCULAR,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.widget_flight = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', -1)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.WIDGET_FLIGHT,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.widget_traveller = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', -1)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.WIDGET_TRAVELLER,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Dashobard.notification_detail = async function (page, cb) {
    const data = new FormData()
    data.append('zamanlanmisSorguId', page.ZamanlanmisSorguId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', -1)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.NOTIFICATION_DETAIL,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, null, null, cb))
}

Dashobard.get_user_info = async function () {
    return fetch({
        url: URL.GET_IP,
        method: 'get'
    })
}

export default Dashobard