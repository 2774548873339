import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanici-listesi`} component={lazy(() => import(`./userList`))} />
        <Route path={`${APP_PREFIX_PATH}/rol-listesi`} component={lazy(() => import(`./roleList`))} />
        <Route path={`${APP_PREFIX_PATH}/izin-listesi`} component={lazy(() => import(`./privilegeList`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanici-grup-turu-listesi`} component={lazy(() => import(`./userGroupType`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanici-grubu-listesi`} component={lazy(() => import(`./userGroup`))} />
        <Route path={`${APP_PREFIX_PATH}/ulke-listesi`} component={lazy(() => import(`./countryList`))} />
        <Route path={`${APP_PREFIX_PATH}/havalimani-listesi`} component={lazy(() => import(`./airportList`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/havayolu-firmalari-listesi`} component={lazy(() => import(`./airlineList`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/islemturleri-listesi`} component={lazy(() => import(`./transactionType`))} />
        <Route path={`${APP_PREFIX_PATH}/bildirimler`} component={lazy(() => import(`./notification`))} />
        <Route path={`${APP_PREFIX_PATH}/harita`} component={lazy(() => import(`./Map/index.js`))} />
        <Route path={`${APP_PREFIX_PATH}/duyuru-listesi`} component={lazy(() => import(`./announcementList`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanicigrubu-kullanicilar`} component={lazy(() => import(`./userGroupUsers`))} />
        <Route path={`${APP_PREFIX_PATH}/ucus-listesi`} component={lazy(() => import(`./flightList`))} />
        <Route path={`${APP_PREFIX_PATH}/yolcu-listesi`} component={lazy(() => import(`./travellerList`))} />
        <Route path={`${APP_PREFIX_PATH}/ucus-yolculari`} component={lazy(() => import(`./travellerByFlight`))} />
        <Route path={`${APP_PREFIX_PATH}/yolcu-detay`} component={lazy(() => import(`./travellerDetail`))} />
        <Route path={`${APP_PREFIX_PATH}/yolcu-bilgi`} component={lazy(() => import(`./travellerInfo`))} />
        <Route path={`${APP_PREFIX_PATH}/yolcu-islemleri`} component={lazy(() => import(`./travellerTransaction`))} />
        <Route path={`${APP_PREFIX_PATH}/rol-kullanicilar`} component={lazy(() => import(`./roleUsers`))} />
        <Route path={`${APP_PREFIX_PATH}/takip-yolcular`} component={lazy(() => import(`./takibeTakilanYolcular`))} />
        <Route path={`${APP_PREFIX_PATH}/takip-yonetim`} component={lazy(() => import(`./targetTraveller`))} />
        <Route path={`${APP_PREFIX_PATH}/mesaj-akisi`} component={lazy(() => import(`./messageList`))} />
        <Route path={`${APP_PREFIX_PATH}/kbb`} component={lazy(() => import(`./KBB`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanici-bildirimleri`} component={lazy(() => import(`./notificationByUsers`))} />
        <Route path={`${APP_PREFIX_PATH}/duyurular`} component={lazy(() => import(`./announcements`))} />
        <Route path={`${APP_PREFIX_PATH}/kayit-listesi`} component={lazy(() => import(`./logList`))} />
        <Route path={`${APP_PREFIX_PATH}/zamanlanmis-sorgu`} component={lazy(() => import(`./queryList`))} />
        <Route path={`${APP_PREFIX_PATH}/onay-listesi`} component={lazy(() => import(`./approvalList`))} />
        <Route path={`${APP_PREFIX_PATH}/cok-ucan-yolcular`} component={lazy(() => import(`./frequentFlyer`))} />
        <Route path={`${APP_PREFIX_PATH}/riskliyolcular-listesi`} component={lazy(() => import(`./riskTravellerList`))} />
        <Route path={`${APP_PREFIX_PATH}/yeni-yolcular`} component={lazy(() => import(`./firstTimeTraveller`))} />
        <Route path={`${APP_PREFIX_PATH}/kullaniciismlemleri`} component={lazy(() => import(`./userProcess`))} />
        <Route path={`${APP_PREFIX_PATH}/yolcu-iletisim-bilgileri`} component={lazy(() => import(`./travellerContactInfo`))} />
        <Route path={`${APP_PREFIX_PATH}/kullanici-ayarlari`} component={lazy(() => import(`./editProfile`))} />
        <Route path={`${APP_PREFIX_PATH}/destek-talebi`} component={lazy(() => import(`./supportRequest`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
