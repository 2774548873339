/* CRUD END POINTS */

export const PRIVILEGE = {
    LIST: "/Api/List/GetPrivilegeList",
    ADD: "/Api/PrivilegeModel",
    EDIT: "/Api/PrivilegeModel/",
    DELETE: "/Api/PrivilegeModel/"
}

export const ROLE = {
    LIST: "/Api/List/GetRoleList",
    PRIVILEGE: "/Api/List/GetRolePrivilegeList",
    ADD: "/Api/RoleModel",
    EDIT: "/Api/RoleModel/",
    DELETE: "/Api/RoleModel/"
}

export const FIRSTTIME = {
    LIST: "/Api/List/GetFirstTimeTravellersList"
}

export const USER_PROCESS = {
    LIST: "/Api/List/GetUserProcessByTcKN"
}

export const TRAVELLER_CONTANT_INFO = {
    LIST: "/Api/List/GetTravellerWithContactInformation"
}

export const TRAVELLERTRANSACTION = {
    LIST: "/Api/List/GetYolcuIslemYapByYolcuId",
    ALL: "/Api/List/GetIslemYapilanYolcular",
    ADD: "/YolcuTakipManagement/AddYolcuIslemYap",
    EDIT: "/YolcuTakipManagement/UpdateYolcuIslemYap",
    DELETE: "Api/YolcuIslemYapModel/"
}

export const TRANSACTION = {
    LIST: "/Api/List/GetIslemTurleri",
    ADD: "/Api/IslemTurleriModel",
    EDIT: "/Api/IslemTurleriModel/",
    DELETE: "/Api/IslemTurleriModel/"
}

export const USER = {
    LIST: "/Api/List/GetUserList",
    ADD: "/Api/UserModel",
    EDIT: "/Api/UserModel/",
    DELETE: "/Api/UserModel/"
}

export const SUPPORT = {
    LIST: "/Api/List/GetReportError",
    RECEIVER: "/Api/List/GetRecevierList",
    ADD: "/YolcuTakipManagement/AddReportError",
    EDIT: "/Api/ReportErrorModel/",
    DELETE: "/Api/ReportErrorModel/"
}

export const USER_GROUP = {
    LIST: "/Api/List/GetMainObjGroupList",
    ADD: "/Api/MainObjGroupModel",
    EDIT: "/Api/MainObjGroupModel/",
    DELETE: "/Api/MainObjGroupModel/",
    DELETE_USER: "/Api/GroupItemModel/"
}

export const USER_GROUP_TYPE = {
    LIST: "/Api/List/GetUserGroupTypeList",
    ADD: "/Api/UserGroupTypeModel",
    EDIT: "/Api/UserGroupType/",
    DELETE: "/Api/UserGroupTypeModel/"
}

export const QUERY = {
    LIST: "/Api/List/GetZamanlanmisSorguList",
    ADD: "/Api/ZamanlanmisSorguModel",
    EDIT: "/Api/ZamanlanmisSorguModel/",
    DELETE: "/Api/ZamanlanmisSorguModel/"
}

export const APPROVAL = {
    LIST: "/Api/List/GetOnayBekleyenKisiTakipList",
    STATUS: "/Api/KisiTakipModel/"
}

export const AIRPORT = {
    LIST: "/Api/List/GetAirportsList",
    TURKEY_LIST: "/Api/List/GetTurkeyAirpotsList",
    ADD: "/Api/AirportsModel",
    EDIT: "/Api/AirportsModel/",
    DELETE: "/Api/AirportsModel/"
}

export const MESSAGE = {
    LIST: "/Message/GetMessages",
}

export const LOG = {
    LIST: "/Api/List/GetLogList",
}

export const FREQUENT_FLYER = {
    LIST: "/Api/List/GetCokUcanYolcularList",
}

export const RISK_TRAVELLER = {
    LIST: "/Api/List/GetRiskliYolcuList",
}

export const AIRLINE = {
    LIST: "/Api/List/GetAirlinesList",
    ADD: "/Api/AirlinesModel",
    EDIT: "/Api/AirlinesModel/",
    DELETE: "/Api/AirlinesModel/"
}

export const TARGET_TRAVELLER = {
    LIST: "/Api/List/GetKisiTakipList",
    SELF: "/Api/List/GetKisiTakipListByCurrentUserId",
    ADD: "/Api/KisiTakipModel",
    TYPES: "/Api/List/GetTakipTurleri",
    DELETE: "/Api/KisiTakipModel/"
}

export const DASHBOARD = {
    NOTIFICATION: "/Api/List/GetNotificationListDashboard",
    TAKIP: "/Api/List/GetKisiTakipListDashboard",
    ANNOUNCEMENT: "/Api/List/GetAnnouncementDashboardList",
    ISLEM_YOLCULAR: "/Api/List/GetIslemYapilanYolcuListDashboard",
    WIDGET_FLIGHT: "/Api/List/GetGelenGidenUcusGrafik",
    WIDGET_TRAVELLER: "/Api/List/GetGelenGidenYolcuGrafik",
    NOTIFICATION_DETAIL: "/Api/List/GetNotificationUserDetails",
    GET_IP: "/SystemManagement/GetIpAdress"
}

export const COUNTRY = {
    LIST: "/Api/List/GetCountryList",
    ADD: "/Api/CountryModel",
    EDIT: "/Api/CountryModel/",
    DELETE: "/Api/CountryModel/"
}

export const NOTIFICATION = {
    LIST: "/Api/List/GetNotificationList",
    ADD: "/Api/NotificationBaseModel/",
    EDIT: "/Api/NotificationBaseModel/",
    USER: "/Api/List/GetAllNotificationByCurrentUser"
}

export const ANNOUNCEMENT = {
    LIST: "/Api/List/GetAnnouncementList",
    ADD: "/Api/AnnouncementModel",
    EDIT: "/Api/AnnouncementModel/",
    DELETE: "/Api/AnnouncementModel/"
}

export const FLIGHT = {
    LIST: "/Api/List/GetFlightList",
    SEATS: "/Api/List/GetSeatingArrangementByFlightId",
    KBB: "/Api/List/GetKbbList"
}

export const EDIT_PROFILE = {
    GET: "/Api/List/GetCurrentUser",
    UPDATE: "Api/UserModel/"
}

export const TRAVELLER = {
    LIST: "/Api/List/GetTravellerList",
    BYFLIGHT: "/Api/List/GetTravellerListByFlightId",
    DETAIL: "/Api/List/GetTravellerDetailByReservationId",
    CONTACT: "/Api/List/GetTravellerContactInfoByReservationId",
    TAKIP: "/Api/List/GetTakibeTakilanYolcuList",
    PAYMENT: "/Api/List/GetTravellerPaymentByReservationId",
    RETINUE: "/Api/List/GetTravellerRetinueByReservationId",
    CHECKIN: "/Api/List/GetCheckinByReservationId",
    LUGGAGE: "/Api/List/GetLuggageByReservationId",
    PHOTO: "/YolcuTakipManagement/GetPhotoByReservationId",
    FLIGHTLIST: "/Api/List/GetFlightListByReservationId",
    CRIMINAL: "/Api/List/GetCriminalListByReservationId",
}