import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { QUERY }  from '../URL'

const Query = {}
const URL = QUERY

Query.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('PasaportNo', item.PasaportNo)
    data.append('InisHavalimani', item.InisHavalimani)
    data.append('YolcuEmail', item.YolcuEmail)
    data.append('Aciklama', item.Aciklama)
    data.append('CountryId', item.CountryId)
    data.append('SorguBitisTarihi', item.SorguBitisTarihi.format('YYYY-MM-DD'))

    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('PasaportNo', item.PasaportNo)
    data.append('InisHavalimani', item.InisHavalimani)
    data.append('YolcuEmail', item.YolcuEmail)
    data.append('Aciklama', item.Aciklama)
    data.append('CountryId', item.CountryId)
    data.append('Tel', item.Tel)
    data.append('SorguBitisTarihi', item.SorguBitisTarihi.format('YYYY-MM-DD'))
    data.append('GecikmesindeSakincaOlanHal', item.GecikmesindeSakincaOlanHal ? '1' : '0')
    if (item.EkBelge) {
        data.append('EkBelge', item.EkBelge.split(",")[1])
    }
    
    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Query