import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'token'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken

	}
	// else {
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }
	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	let notificationParam = {
		message: ''
	}
	if (Array.isArray(response.data?.errorMessages) && response.data?.errorMessages.length > 0) {
		notificationParam.message = response.data?.errorMessages[0]
		notification.error(notificationParam)
	}

	if (Array.isArray(response.data?.ErrorMessages) && response.data?.ErrorMessages.length > 0) {
		notificationParam.message = response.data?.ErrorMessages[0]
		notification.error(notificationParam)
	}
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (!error.response?.status) return;
	if (error.response?.status === 401) {
		if (error?.response?.config?.url !== '/Authorazation/Login') {
			localStorage.removeItem(AUTH_TOKEN)
			localStorage.removeItem('USER')
			history.push(ENTRY_ROUTE)
			window.location.reload();
		}
	}

	if (Array.isArray(error.response.data?.errorMessages) && error.response.data?.errorMessages.length > 0) {
		notificationParam.message = error.response.data?.errorMessages[0]
		notification.error(notificationParam)
	}

	if (Array.isArray(error.response.data?.ErrorMessages) && error.response.data?.ErrorMessages.length > 0) {
		notificationParam.message = error.response.data?.ErrorMessages[0]
		notification.error(notificationParam)
	}


	return Promise.reject(error);
});

export default service