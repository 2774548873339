import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { FLIGHT } from '../URL'

const Flight = {}
const URL = FLIGHT

Flight.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('kalkisTarihiOnce', page.departureDate ?? '')
    data.append('kalkisTarihiSonra', page.arrivalDate ?? '')
    data.append('kalkisHavalimani', page.departureAirline ?? '')
    data.append('varisHavalimani', page.arrivalAirline ?? '')
    data.append('ucusNumarasi', page.flightID ?? '')
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Flight.get_seat = async function ({id}, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('flightId', id)
    data.append('limit', -1)

    fetch({
        url: URL.SEATS,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Flight.kbb = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    if (page.flightId) {
        data.append('ucusNumarasi', page.flightId)
    };
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.KBB,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Flight