import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import moment from 'moment';
import 'moment/locale/tr';
import {useIdleTimer} from 'react-idle-timer'
import { signOut } from 'redux/actions/Auth';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location, history }) => {
        // const item = navigationConfig[0].submenu.find(i => i.path === location.pathname)
        if (!isAuthenticated?.token) return (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )

        // if (item?.roles) {
        //   const userData = localStorage.getItem('USER')
        //   const userRoles = userData ? JSON.parse(userData) : null
        //   const haveAllRole = userRoles.roles.every((element) =>
        //     item.roles.includes(element)
        //   );
        //   if (haveAllRole === false) return history.goBack();
        // }

        return children
      }}
    />
  );
}

export const Views = (props) => {
  const { auth, location, direction } = props;
  const currentAppLocale = AppLocale.tr;
  useBodyClass(`dir-${direction}`);
  moment.locale("tr");
  const dispatch = useDispatch()
  useIdleTimer({
    // 15 minutes
    timeout: 900000,
    onIdle: () => {
      dispatch(
        signOut()
      )
    },
    debounce: 500
  })
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={auth}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  return { locale, direction, auth }
};

export default withRouter(connect(mapStateToProps)(Views));