import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { AIRPORT } from '../URL'

const Airport = {}
const URL = AIRPORT

Airport.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}


Airport.turkeyList = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('orderColumnAndDirection', 1)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.TURKEY_LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Airport.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.ReservedName)
    data.append('IataCode', item.IataCode)
    data.append('IcaoCode', item.IcaoCode)
    data.append('CountryId', item.CountryId)
    data.append('Enlem', item.Enlem)
    data.append('Boylam', item.Boylam)
    data.append('GKod', item.GKod)

    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Airport.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.ReservedName)
    data.append('IataCode', item.IataCode)
    data.append('IcaoCode', item.IcaoCode)
    data.append('CountryId', item.CountryId)
    data.append('Enlem', item.Enlem)
    data.append('Boylam', item.Boylam)
    data.append('GKod', item.GKod)
    
    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Airport.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Airport