
const dev = {
	API_ENDPOINT_URL: 'https://yolcutakipapi.solvera.com.tr/',
	TILE_SERVER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	QUERY_ENGINE_URL: 'http://85.105.70.95:30184/query-engine/api/v1'
};

const prod = {
	API_ENDPOINT_URL: 'https://yolcutakipapi.solvera.com.tr/',
	TILE_SERVER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	QUERY_ENGINE_URL: 'http://85.105.70.95:30184/query-engine/api/v1'
};

const test = {
	API_ENDPOINT_URL: 'https://yolcutakipapi.solvera.com.tr',
	TILE_SERVER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	QUERY_ENGINE_URL: 'http://85.105.70.95:30184/query-engine/api/v1'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
