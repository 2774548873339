import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TRAVELLERTRANSACTION } from '../URL'

const TravellerTransaction = {}
const URL = TRAVELLERTRANSACTION

TravellerTransaction.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('yolcuId', page.yolcuId)
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)
    
    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TravellerTransaction.all = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.ALL,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TravellerTransaction.edit = async function (role, setData, loading, cb) {
    console.log(role)
    if (loading) loading(true)
    const data = new FormData()
    data.append('YolcuId', role.YolcuId)
    data.append('IslemTuru', role.IslemTuruId)
    data.append('Aciklama', role.Aciklama)
    data.append('AktifMi', role.AktifMi)
    data.append('KomutaKontroleBildir', role.KomutaKontroleBildir)
    if (role?.EkBelge) {
        data.append('EkBelge', role.EkBelge.split(",")[1])
        data.append('SilindiMi', 'false')
    } else {
        data.append('EkBelge', '')
        data.append('SilindiMi', 'true')
    }
    FilterManager(data, role.filters)

    fetch({
        url: URL.EDIT + '?idStr=' + role.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TravellerTransaction.add = function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('YolcuId', role.YolcuId)
    data.append('IslemTuru', role.IslemTuru)
    data.append('Aciklama', role.Aciklama)
    data.append('AktifMi', role.AktifMi)
    data.append('KomutaKontroleBildir', role.KomutaKontroleBildir)
    if (role?.EkBelge) {
        data.append('EkBelge', role.EkBelge.split(",")[1])
    }else {
        data.append('EkBelge', "")
    }

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TravellerTransaction.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default TravellerTransaction