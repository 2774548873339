import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TRAVELLER } from '../URL'
import moment from "moment"

const Traveller = {}
const URL = TRAVELLER

Traveller.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('pasaportNo', page.passportNo)
    data.append('adi', page.name)
    data.append('soyadi', page.surname)
    data.append('cinsiyet', page.gender)
    data.append('uyruk', page.uyruk)
    data.append('dogumTarihiIlk', page?.bornDateBefore ? moment(page?.bornDateBefore).format('YYYY-MM-DD') : '')
    data.append('dogumTarihiSon', page?.bornDateAfter ? moment(page?.bornDateAfter).format('YYYY-MM-DD') : '')
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('ucusTarihIlk', page?.startDate ? moment(page?.startDate).format('YYYY-MM-DD') : '')
    data.append('ucusTarihSon', page?.endDate ? moment(page?.endDate).format('YYYY-MM-DD') : '')
    FilterManager(data, page.filters)
    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.byFlight = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('flightId', page.flightId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)
    
    fetch({
        url: URL.BYFLIGHT,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.detail = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.DETAIL,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.payment = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.PAYMENT,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.checkin = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.CHECKIN,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.get_photo = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', -1)

    fetch({
        url: URL.PHOTO,
        method: 'post',
        data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.luggage = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.LUGGAGE,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.contact = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.CONTACT,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.retinue = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.RETINUE,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.flight_list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.FLIGHTLIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.criminal_list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('reservationId', page.reservationId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    fetch({
        url: URL.CRIMINAL,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Traveller.takip = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.TAKIP,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Traveller