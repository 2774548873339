import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { USER_GROUP } from '../URL'

const UserGroup = {}
const URL = USER_GROUP

UserGroup.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)


    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

UserGroup.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.KullaniciGrubuAdi)
    data.append('TypeId', item.KullaniciGrubuTipId)

    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

UserGroup.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.KullaniciGrubuAdi)
    data.append('TypeId', item.KullaniciGrubuTipId)
    
    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

UserGroup.addUser = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    if (!item.UserGroupId) return;
    try {
        for await (const id of item.UserId) {
            const data = new FormData()
            data.append('GroupId', item.UserGroupId)
            data.append('UserId', id)
    
            await fetch({
                url: "/Api/GroupItemModel",
                method: 'post',
                data: data
            })
        }
        callbackManager('', setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

UserGroup.getUsers = function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('groupId', page.groupId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    
    fetch({
        url: "/Api/List/GetUserGroupUsersByGroupId",
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

UserGroup.delete_user = async function (item, setData, loading, cb) {
    if (loading) loading(true)

    fetch({
        url: URL.DELETE_USER + item,
        method: 'delete'
    })
}

UserGroup.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default UserGroup